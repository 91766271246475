<template>
  <div class="tips-box" v-if="display">
    <p class="tips-text">{{ content }}</p>
  </div>
</template>

<script>
import DataTips from "./dataTips.js";
export default DataTips;
</script>

<style lang="scss" scoped>
@import "./dataTips.scss";
</style>