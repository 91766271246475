export default {
  props: {
    //传入数据
    value: {
      type: [Array, String, Number, Object]
    },
    //提示文案
    text: {
      type: String,
      default: "暂无数据"
    }
  },
  data() {
    return {
      display: true, //是否显示
      content: "加载中..." //内容
    };
  },
  created() {

  },
  mounted() {

  },
  methods: {
    //判断状态
    judge(val) {
      //数组类型
      if (typeof val == "array") {
        if (val.length > 0) {
          this.display = false
        } else {
          this.content = this.text
        }
      }
      //字符串类型、数值类型
      if (typeof val == "string" || typeof val == "number") {
        if (val) {
          this.display = false
        } else {
          this.content = this.text
        }
      }
      //对象类型
      if (typeof val == "object") {
        if (Object.keys(val).length > 0) {
          this.display = false
        } else {
          this.content = this.text
        }
      }
    }
  },
  watch: {
    value(val) {
      this.judge(val)
    }
  }
};